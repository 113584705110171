import { Outlet } from '@remix-run/react';
import { useState } from 'react';
import { withAuthentication } from '~/components/auth';
import Header from '~/layout/header';
import Sidebar from '~/layout/sidebar';
import {
  LocationStatus_Enum,
  UserRole_Enum,
  useCurrentUserQuery,
} from '../../hasura/__generated__';
import UserHeader from '../../layout/header/user-header';
import UserSidebar from '../../layout/header/user-sidebar';
import { useTranslation } from 'react-i18next';
import { getUser } from '~/utils/localStorage';

function AppLayout() {
  const { t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const { role } = getUser();
  const { data, loading } = useCurrentUserQuery();

  const currentUser = data?.getCurrentUser;

  if (role === UserRole_Enum.User)
    return (
      <div className="relative flex min-h-screen flex-col bg-homy-gray-bg">
        <UserHeader setShowSidebar={setShowSidebar} />
        <div className="flex min-h-screen flex-row">
          <UserSidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
          <main className="w-full pt-16">
            <Outlet />
          </main>
        </div>
      </div>
    );

  // if user is building manager
  // check if the location is active
  // if not, show the pending activation page
  if (role === UserRole_Enum.BuildingManager) {
    if (
      !loading &&
      (!currentUser?.location?.id ||
        currentUser?.location?.status !== LocationStatus_Enum.Active ||
        currentUser?.location.transitionStatus !== null)
    ) {
      return (
        <div className="relative flex min-h-screen flex-col bg-homy-gray-bg">
          <Header />
          <div className="flex min-h-screen flex-row">
            <main className="w-full pt-16">
              <div className="mt-14 flex flex-col items-center text-center">
                <p className="h4 pb-4">{t('pages.activation.pending.title')}</p>
                <p className="h6 pb-2">
                  {t('pages.activation.pending.headline', {
                    name: data?.getCurrentUser.name,
                  })}
                </p>
                <p>{t('pages.activation.pending.description')}</p>
              </div>
            </main>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="relative flex min-h-screen flex-col bg-homy-gray-bg">
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="flex min-h-screen flex-row">
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <main className="w-full pt-16 sm:pl-[6.563rem] lg:pl-[16rem]">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default withAuthentication(AppLayout);
